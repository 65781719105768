export const columns = [
  {
    title: '编号',
    dataIndex: 'slug',
    width: 150,
    fixed: 'left'
  },
  {
    title: '中文名',
    width: 150,
    dataIndex: 'name'
  },
  {
    title: '拉丁名',
    dataIndex: 'sciname',
    width: 400,
    scopedSlots: {
      customRender: 'sciname'
    }
  },
  {
    title: '图片',
    dataIndex: 'pic_count',
    scopedSlots: {
      customRender: 'pic-count'
    },
    width: '4rem'
  },
  {
    title: '序列',
    dataIndex: 'seq_count',
    scopedSlots: {
      customRender: 'seq-count'
    },
    width: '4rem'
  },
  {
    title: '操作',
    scopedSlots: {
      customRender: 'action'
    },
    fixed: 'right'
  }
];

export const synonymColumns = [
  {
    title: '名称',
    dataIndex: 'value',
    scopedSlots: {
      customRender: 'value'
    }
  },
  {
    title: '操作',
    scopedSlots: {
      customRender: 'action'
    }
  }
];