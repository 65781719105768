<template>
  <div id="main-content">
    <Create
      :visible="createVisible"
      @close="onCreateClosed"
      @created="onCreated"
    />
    <Upload
      :visible="uploadVisible"
      @close="onUploadClosed"
    />
    <Update
      :visible="updateVisible"
      :species-item="speciesItem"
      @close="onUpdateClosed"
      @updated="onUpdated"
    />
    <a-row :gutter="24">
      <a-col :span="4">
        <DFSAdminNavbar active="物种管理" />
      </a-col>
      <a-col :span="20">
        <Search @create="onCreate" @search="onSearch" @upload="onUpload" />
        <div class="admin-content">
          <a-table
            :loading="loading"
            :pagination="pagination"
            :columns="columns"
            :data-source="species"
            :row-key="speciesItem => speciesItem.slug"
            @change="onChange"
          >
            <template slot="sciname" slot-scope="text">
              <span v-html="text"></span>
            </template>
            <a
              :href="`/admin/species/${record.slug}/pics`"
              target="_blank"
              slot="pic-count"
              slot-scope="text, record"
            >
              {{ text }}
            </a>
            <a
              :href="`/admin/species/${record.slug}/seqs`"
              target="_blank"
              slot="seq-count"
              slot-scope="text, record"
            >
              {{ text }}
            </a>
            <span slot="created-time" slot-scope="text">
              {{ text | moment }}
            </span>
            <span slot="action" slot-scope="record">
              <a @click="onUpdate(record)">编辑</a>
              <a-divider type="vertical" />
              <a-popconfirm
                title="您确定要删除该物种吗？"
                placement="bottom"
                @confirm="onDestroy(record.slug)"
              >
                <a>删除</a>
              </a-popconfirm>
            </span>
          </a-table>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import DFSAdminNavbar from '@/components/DFSAdminNavbar';
import { columns } from './utils';
import Create from './Create';
import Upload from './Upload';
import Update from './Update';
import Search from './Search';

import { destroy, list } from '@/services/species';

export default {
  name: 'Content',
  components: {
    DFSAdminNavbar,
    Create,
    Upload,
    Update,
    Search,
  },
  data() {
    return {
      loading: false,
      pagination: {},
      columns,
      queryParams: {
        q: ''
      },
      species: [],
      speciesItem: {},
      createVisible: false,
      uploadVisible: false,
      updateVisible: false,
      editSpecies: false
    };
  },
  methods: {
    fetch(queryParams) {
      this.loading = true;
      list(queryParams).then(({ data }) => {
        this.loading = false;
        const pager = { ...this.pagination };
        pager.total = data.data.count;
        this.pagination = pager;
        this.species = data.data.results;
      });
    },
    onChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      const queryParams = { ...this.queryParams };
      queryParams.page = pagination.current;
      this.fetch(queryParams);
    },
    onCreate() {
      this.createVisible = true;
    },
    onCreateClosed() {
      this.createVisible = false;
    },
    onCreated() {
      this.createVisible = false;
      const queryParams = { ...this.queryParams };
      if (this.pagination.current) {
        queryParams.page = this.pagination.current;
      }
      this.fetch(queryParams);
    },
    onUpload() {
      this.uploadVisible = true;
    },
    onUploadClosed() {
      this.uploadVisible = false;
    },
    onDestroy(slug) {
      destroy(slug).then(() => {
        this.$message.info('物种' + slug + '已被成功删除。');
        const queryParams = { ...this.queryParams };
        if (this.pagination.current) {
          queryParams.page = this.pagination.current;
        }
        this.fetch(queryParams);
      });
    },
    onUpdate(record) {
      this.speciesItem = record;
      // this.editSpecies = true;
      this.updateVisible = true;
    },
    onUpdateClosed() {
      this.updateVisible = false;
    },
    onUpdated() {
      this.updateVisible = false;
      const queryParams = { ...this.queryParams };
      if (this.pagination.current) {
        queryParams.page = this.pagination.current;
      }
      this.fetch(queryParams);
    },
    onSearch(queryParams) {
      const pager = { ...this.pagination };
      pager.current = 1;
      this.pagination = pager;
      this.queryParams = queryParams;
      this.fetch(queryParams);
    },
    onPicAdmin(record) {
      this.speciesItem = record;
      this.picAdminVisible = true;
    },
    onPicAdminOk() {
      this.picAdminVisible = false;
    },
    onPicAdminCancel() {
      this.picAdminVisible = false;
    },
    onSeqAdmin(record) {
      this.speciesItem = record;
      this.seqAdminVisible = true;
    },
    onSeqAdminOk() {
      this.seqAdminVisible = false;
    },
    onSeqAdminCancel() {
      this.seqAdminVisible = false;
    }
  },
  mounted() {
    this.fetch(this.queryParams);
  }
}
</script>


<style lang="stylus" scoped>
#main-content
  padding-top: 2rem

  .ops
    margin-bottom: 1rem
</style>
