import service from "@/utils/service";


export function getTaskStatus(task_id) {
    return service({
        method: 'get',
        url: '/v1/tasks/' + task_id + '/status/'
    });
}


export function getTaskOutput(task_id) {
    return service({
        method: 'get',
        url: '/v1/tasks/' + task_id + '/output/'
    });
}