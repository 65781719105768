export function getTaxonDressed(taxon) {
  return {
    slug: taxon.slug,
    name: taxon.name,
    sciname: taxon.sciname,
    rank: taxon.rank,
    isLeaf: taxon.children_count == 0
  };
}


export function getTaxonUndressed(taxon) {
  return {
    slug: taxon.slug,
    name: taxon.name,
    sciname: taxon.sciname,
    rank: taxon.rank
  };
}
