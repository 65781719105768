import service from '@/utils/service';


export function upload(data) {
    return service({
        method: 'post',
        url: '/v1/uploads/',
        data: data
    });
}
