<template>
  <a-modal
    title="编辑物种"
    :visible="visible"
    :width="800"
    @cancel="onClose"
    @ok="onConfirm"
  >
    <a-form
      :label-col="{span: 4}"
      :wrapper-col="{span: 20}"
    >
      <a-form-item label="编号" required>
        <a-input v-model="form.slug" disabled />
      </a-form-item>
      <a-form-item label="中文名" required>
        <a-input
          placeholder="请输入中文名"
          v-model="form.name"
          v-decorator="[
            'name',
            { rules: [{ required: true, message: '中文名不能为空', trigger: 'blur'}] }
          ]"
        />
      </a-form-item>
      <a-form-item label="拉丁名" required>
        <a-input
          :style="{'font-style': 'italic'}"
          v-model="form.sciname"
          placeholder="请输入拉丁名"
        />
      </a-form-item>
      <a-form-item label="异名">
        <div v-for="synonymItem, index in form.synonym" :key="index">
          {{ synonymItem }}
          <a-divider type="vertical" />
          <a @click="onDestroySynonymItem(synonymItem)">删除</a>
        </div>
        <div v-if="createNewSynonymItem">
          <a-input v-model="newSynonymItem" @keyup.enter="onNewSynonymItemCreated" />
          <a @click="onNewSynonymItemCreated">保存</a>
        </div>
        <div v-else>
          <a @click="() => createNewSynonymItem = true">添加</a>
        </div>
      </a-form-item>
      <a-form-item label="分类" required>
        <div v-if="updateLineage">
          <a-cascader
            placeholder="请选择分类"
            :field-names="{ label: 'name', value: 'slug', children: 'children' }"
            :options="taxons"
            :load-data="loadSubTaxons"
            change-on-select
            @change="onLineageUpdated"
            @blur="() => updateLineage = false"
          />
        </div>
        <div v-else>
          <span>{{ form.lineage.map(taxon => taxon.name).join('/') }}</span>
          <a-divider type="vertical" />
          <a @click="() => updateLineage = true">修改</a>
        </div>
      </a-form-item>
      <a-form-item label="形态描述" required>
        <a-textarea v-model="form.morphology" :rows="12" placeholder="请输入形态描述" />
      </a-form-item>
      <a-form-item label="参考文献">
        <div v-for="reference, index in form.references" :key="index">
          {{ reference }}
          <a-divider type="vertical" />
          <a @click="onDestroyReference(reference)">删除</a>
        </div>
        <div v-if="createNewReference">
          <a-input v-model="newReference" @keyup.enter="onNewReferenceCreated" />
          <a @click="onNewReferenceCreated">保存</a>
        </div>
        <div v-else>
          <a @click="() => createNewReference = true">添加</a>
        </div>
      </a-form-item>
      <a-form-item label="分布地">
        <div v-for="(distributionItem, index) in form.distribution" :key="index">
          {{ distributionItem.map(district => district.name).join('/') }}
          <a-divider type="vertical" />
          <a @click="onDestroyDistributionItem(distributionItem)">删除</a>
        </div>
        <a-cascader
          placeholder="请选择地址"
          :field-names="{ label: 'name', value: 'name', children: 'districts' }"
          :options="districts"
          :load-data="loadDistricts"
          change-on-select
          @change="onNewDistributionItemChanged"
          @blur="onNewDistributionItemBlurred"
        >
          <a>添加</a>
        </a-cascader>
      </a-form-item>
      <a-form-item label="采集地">
        <div v-for="(collectionItem, index) in form.collection" :key="index">
          {{ collectionItem.map(district => district.name).join('/') }}
          <a-divider type="vertical" />
          <a @click="onDestroyCollectionItem(collectionItem)">删除</a>
        </div>
        <a-cascader
          placeholder="请选择地址"
          :field-names="{ label: 'name', value: 'name', children: 'districts' }"
          :options="districts"
          :load-data="loadDistricts"
          change-on-select
          @change="onNewCollectionItemChanged"
          @blur="onNewCollectionItemBlurred"
        >
          <a>添加</a>
        </a-cascader>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { list } from '@/services/taxon';
import { update } from '@/services/species';
import { list as listDistricts } from '@/services/district';
import {
  getTaxonDressed,
  getTaxonUndressed
} from '@/utils/taxon';
import {
  compareDistrict,
  compareDistricts,
  getDistrictDressed,
  getDistrictUndressed
} from '@/utils/district';

export default {
  name: 'Update',
  components: {
  },
  props: {
    visible: Boolean,
    speciesItem: Object
  },
  data() {
    return {
      newSynonymItem: '',
      createNewSynonymItem: false,
      taxons: [],
      updateLineage: false,
      newReference: '',
      createNewReference: false,
      districts: [],
      newDistributionItem: [],
      newCollectionItem: [],
      form: {
        slug: '',
        name: '',
        sciname: '',
        synonym: [],
        lineage: [],
        morphology: '',
        references: [],
        distribution: [],
        collection: []
      }
    };
  },
  watch: {
    speciesItem(value) {
      this.form = {
        slug: value.slug,
        name: value.name,
        sciname: value.sciname,
        synonym: value.synonym,
        lineage: value.lineage,
        morphology: value.morphology,
        references: value.references,
        distribution: value.distribution,
        collection: value.collection
      };
    }
  },
  methods: {
    onDestroySynonymItem(synonymItem) {
      this.form.synonym = this.form.synonym.filter(
        i => i !== synonymItem
      );
    },
    onNewSynonymItemCreated() {
      if (this.newSynonymItem === '') {
        return;
      }
      if (this.form.synonym.includes(this.newSynonymItem)) {
        return;
      }
      this.form.synonym.push(this.newSynonymItem);
      this.form.synonym = [ ...this.form.synonym ];
      this.newSynonymItem = '';
      this.createNewSynonymItem = false;
    },
    fetchKingdoms() {
      list({ rank: '界' }).then(({ data }) => {
        this.taxons = data.data.results.map(getTaxonDressed);
      });
    },
    loadSubTaxons(selectedOptions) {
      const taxon = selectedOptions[selectedOptions.length - 1];
      taxon.loading = true;
      list({ parent: taxon.slug }).then(({ data }) => {
        taxon.loading = false;
        taxon.children = data.data.results.map(getTaxonDressed);
        this.taxons = [ ...this.taxons ];
      });
    },
    onLineageUpdated(value, selectedOptions) {
      this.form.lineage = selectedOptions.map(getTaxonUndressed);
      const last = selectedOptions[selectedOptions.length - 1];
      if (last.isLeaf) {
        this.updateLineage = false;
      }
    },
    onDestroyReference(reference) {
      this.form.references = this.form.references.filter(
        i => i !== reference
      );
    },
    onNewReferenceCreated() {
      if (this.newReference === '') {
        return;
      }
      if (this.form.references.includes(this.newReference)) {
        return;
      }
      this.form.references.push(this.newReference);
      this.form.references = [ ...this.form.references ];
      this.newReference = '';
      this.createNewReference = false;
    },
    fetchProvinces() {
      listDistricts({}).then(({ data }) => {
        const districts = data.districts[0].districts.map(
          getDistrictDressed
        );
        districts.sort(compareDistrict);
        this.districts = districts;
      });
    },
    loadDistricts(selectedOptions) {
      const district = selectedOptions[selectedOptions.length - 1];
      district.loading = true;
      listDistricts({ keywords: district.name }).then(({ data }) => {
        district.loading = false;
        const districts = data.districts[0].districts.map(
          getDistrictDressed
        );
        districts.sort(compareDistrict);
        district.districts = districts;
        this.districts = [ ...this.districts ];
      });
    },
    onDestroyDistributionItem(distributionItem) {
      this.form.distribution = this.form.distribution.filter(
        i => ! compareDistricts(i, distributionItem)
      );
    },
    onNewDistributionItemChanged(value, selectedOptions) {
      const last = selectedOptions[selectedOptions.length - 1];
      this.newDistributionItem = selectedOptions.map(getDistrictUndressed);
      if (last.isLeaf) {
        this.form.distribution.push(this.newDistributionItem);
        this.newDistributionItem = [];
        this.form.distribution = [ ...this.form.distribution ];
      }
    },
    onNewDistributionItemBlurred() {
      if (this.newDistributionItem.length === 0) {
        return;
      }
      this.form.distribution.push(this.newDistributionItem);
      this.newDistributionItem = [];
      this.form.distribution = [ ...this.form.distribution ];
    },
    onDestroyCollectionItem(collectionItem) {
      this.form.collection = this.form.collection.filter(
        i => ! compareDistricts(i, collectionItem)
      );
    },
    onNewCollectionItemChanged(value, selectedOptions) {
      const last = selectedOptions[selectedOptions.length - 1];
      this.newCollectionItem = selectedOptions.map(getDistrictUndressed);
      if (last.isLeaf) {
        this.form.collection.push(this.newCollectionItem);
        this.newCollectionItem = [];
        this.form.collection = [ ...this.form.collection ];
      }
    },
    onNewCollectionItemBlurred() {
      if (this.newCollectionItem.length === 0) {
        return;
      }
      this.form.collection.push(this.newCollectionItem);
      this.newCollectionItem = [];
      this.form.collection = [ ...this.form.collection ];
    },
    onConfirm() {
      const data = { ...this.form };
      const slug = data.slug;
      delete data.slug;
      update(slug, data).then(() => {
        this.$emit('updated');
      });
    },
    onClose() {
      this.$emit('close');
    }
  },
  mounted() {
    this.fetchKingdoms();
    this.fetchProvinces();
  },
}
</script>