export function compareDistrict(district1, district2) {
  const adcode1 = district1.adcode;
  const adcode2 = district2.adcode;
  if (adcode1 > adcode2) {
    return 1;
  }
  if (adcode1 < adcode2) {
    return -1;
  }
  return 0;
}


export function compareDistricts(districts1, districts2) {
  if (districts1.length !== districts2.length) {
    return false;
  }
  for (var i=0; i<districts1.length; i++) {
    if (districts1[i].adcode !== districts2[i].adcode) {
      return false;
    }
  }
  return true;
}


export function getDistrictDressed(district) {
  return {
    adcode: district.adcode,
    name: district.name,
    center: district.center,
    level: district.level,
    isLeaf: district.level === 'street'
  };
}


export function getDistrictUndressed(district) {
  return {
    adcode: district.adcode,
    name: district.name,
    center: district.center,
    level: district.level
  };
}