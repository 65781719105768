<template>
  <a-form-model
    class="ops"
    layout="inline"
    :model="form"
  >
    <a-form-model-item>
      <a-input
        placeholder="中文名、拉丁名、异名"
        v-model="form.q"
        @pressEnter="onSearch"
      />
    </a-form-model-item>
    <a-form-model-item>
      <a-button
        type="primary"
        icon="search"
        :style="{ marginRight: '1rem' }"
        @click="onSearch"
      >
        搜索
      </a-button>
      <a-button
        type="primary"
        icon="plus"
        :style="{ marginRight: '1rem' }"
        @click="onCreate"
      >
        新建
      </a-button>
      <a-button
          type="primary"
          icon="plus"
          @click="onUpload"
      >
        批量上传
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
export default {
  name: 'Search',
  data() {
    return {
      form: {
        q: ''
      }
    };
  },
  methods: {
    onSearch() {
      this.$emit('search', this.form);
    },
    onCreate() {
      this.$emit('create');
    },
    onUpload() {
      this.$emit('upload');
    }
  }
}
</script>
