<template>
  <a-modal
    title="批量上传"
    :visible="visible"
    :width="800"
    :footer="null"
    :closable="false"
  >
    <a-spin tip="批量创建中，请不要关闭弹窗。" :spinning="creating">
      <div v-show="!done">
        <p>（1）上传文件需要是一个zip压缩包。</p>
        <p>（2）压缩包内包含一个文件【VSL数据模板.xlsx】和两个文件夹【Images】、【Sequences】。</p>
        <p>（3）【VSL数据模板.xlsx】文件是一个Excel表格，包含编号、中文名、拉丁名、异名、分类系统、形态描述、参考文献、分布地、采集地9列。</p>
        <p>（4）【Images】文件夹存储物种图片，一个物种一个子文件夹，子文件夹的命名方式为【物种编号】。</p>
        <p>（5）【Sequences】文件夹存储物种序列，一个物种一个子文件，子文件的命名方式为【物种编号.fasta】。</p>
        <a-divider />
        <a-form v-show="!done">
          <a-form-item label="文件" :label-col="{span: 5}" :wrapper-col="{span: 16}">
            <a-row :gutter="8">
              <a-col :span="20">
                <a-input placeholder="请选择文件" :disabled="true" v-model="filename"></a-input>
              </a-col>
              <a-col :span="4">
                <a-upload :show-upload-list="false" :file-list="fileList" :before-upload="beforeUpload">
                  <a-button>选择文件</a-button>
                </a-upload>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item :wrapper-col="{span: 16, offset: 5}">
            <a-button type="primary" :disabled="fileList.length === 0" @click="handleUpload">
              批量上传
            </a-button>
            <a-button style="margin-left: 10px;" @click="onClose">关闭</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-spin>
    <a-result
      v-show="created"
      status="success"
      title="批量上传成功"
    >
      <template #extra>
        <a-button key="console" type="primary" @click="onUploadAgain">再上传一个</a-button>
        <a-button key="close-upload" @click="onClose">关闭</a-button>
      </template>
    </a-result>
    <a-result
      v-show="createFailed"
      status="error"
      title="批量上传失败"
    >
      <template #extra>
        <a-button key="upload-again" type="primary" @click="onUploadAgain">重新上传</a-button>
        <a-button key="close-upload" @click="onClose">关闭</a-button>
      </template>

      <div class="desc">
        <p style="font-size: 16px;"><strong>错误详细信息:</strong></p>
        <pre>{{ output }}</pre>
      </div>
    </a-result>
  </a-modal>
</template>

<script>
import { upload } from '@/services/upload';
import { createBulkCreate } from '@/services/bulkCreate';
import { getTaskStatus, getTaskOutput } from '@/services/task';

export default {
  name: 'Upload',
  props: {
    visible: Boolean
  },
  data: function () {
    return {
      filename: '',
      fileList: [],
      creating: false,
      created: false,
      createFailed: false,
      done: false,
      output: ''
    };
  },
  methods: {
    beforeUpload(file) {
      this.fileList = [file];
      this.filename = file.name;
      return false;
    },
    handleUpload() {
      this.creating = true;
      let formData = new FormData();
      formData.append('file', this.fileList[0]);
      upload(formData).then(res => {
        createBulkCreate({
          uuid: res.data['data']['uuid']
        }).then(res => {
          let task_id = res.data['data']['task_id'];
          let timer = setInterval(() => {
            getTaskStatus(task_id).then(req => {
              let status = req.data['data']['status'];
              if (status === 1) {
                clearInterval(timer);
                this.creating = false;
                this.created = true;
                this.done = true;
              } else if (status === -1) {
                clearInterval(timer);
                getTaskOutput(task_id).then(req => {
                  let stderr = req.data['data']['stderr']
                  if (stderr === null) {
                    stderr = '';
                  }
                  this.output = stderr;
                  this.creating = false;
                  this.createFailed = true;
                  this.done = true;
                });
              }
            });
          }, 5000);
        });
      });
    },
    reset() {
      this.filename = '';
      this.fileList = [];
      this.creating = false;
      this.created = false;
      this.createFailed = false;
      this.done = false;
      this.output = '';
    },
    onUploadAgain() {
      this.reset();
    },
    onClose() {
      this.reset();
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
</style>